.flex {
    display: flex;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}
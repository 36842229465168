#work-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
}

#work-nav p {
    margin: 0px 15px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
}

#work-nav p.selected {
    color: #DFFF11;
}

#coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;

    margin-top: -75px;
}
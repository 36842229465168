.outer-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    flex: 1;
    
    width: 100%;
}

.page-container {
    margin: 15px;
    margin-bottom: 0px;
    text-align: left;
    word-wrap: normal;
    word-break: break-all;
    max-width: 1000px;
}
#header {
    height: 110px;
    width: 100%;

    padding: 25px;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-items: center;
}

#logo-container {
    width: 50px;
    height: 50px;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

#header-logo {
    width: 100%;
    height: 100%;
    
    transition: all 0.15s;
}

#logo-container:active #header-logo {
    width: 90%;
    height: 90%;
}

#header-logo path {
    fill: var(--primary);
}

#header-title {
    position: static;
    left: 50vw;
    text-align: left;
}

#header-hamburger {
    width: 20px;
    height: 20px;
    margin-right: 25px;
    transform: scale(100%);
    transition: transform 0.25s;
}

#header-hamburger:hover {
    transform: scale(110%);
    margin-right: 25px;
}

#desktop-nav {
    display: none;
    margin-right: 20px;
}

.link-container {
    margin:  0px 10px;
}

.link-container p {
    color: var(--white);
    transition: color 0.25s;
}

.link-container.selected p {
    color: var(--primary);
}

.link-container:hover p {
    color: var(--secondary);
}

#desktop-nav a {
    text-decoration: none;
}

#spacer {
    flex: 1;
}

@media screen and (min-width: 1024px) {
   #header-hamburger {
       display: none;
   } 
   #header-title {
       display: none;
   }
   #desktop-nav {
       display: flex;
       flex-direction: row;
   }
}
.input {
    font-size: var(--base-px-size);
    padding: 11px;
    box-sizing: border-box;
    border: 1px solid white;
    background-color: black;
    color: white;
    border-radius: 5px;
    margin: 10px 0px;
    width: 100%;
    display: block;
}
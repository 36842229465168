#home-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cta-container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1000px;
    padding: 15px;
}

#connect-button {
    margin-top: 30px;
}

#map-header {
    text-align: right;
}

#map {
    background-image: url('./Asset\ 1.svg');
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 200px 100px -50px #000000, inset 0px -200px 100px -50px #000000;
}
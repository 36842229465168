button {
    cursor: pointer;
    border-radius: 5px;
}

button, .outlined {
    background-color: var(--black);

    border: 3px solid var(--primary);
}

button, .filled {
    background-color: var(--primary);
}
  
button p {
    margin: 12px;
    
    color: var(--primary);
}

button, .filled p {
    color: var(--black);
}


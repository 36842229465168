.text-box {
    padding: 15px;
    background-color: black;
    margin: 0px 0px 15px 0px;
}

.text-box * {
    margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--white);

    font-weight: 400;
    font-family: 'Playfair Display', serif;
    
    line-height: 95%;
    
    word-break: keep-all;
    white-space: pre-line;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

h1 {
    color: var(--primary);

    font-size: calc(var(--base-px-size) * 5.333);
    font-weight: 900;
}
  
  
h2 {
    font-size: calc(var(--base-px-size) * 3.555);
    font-weight: 700;
}
  
h3 {
    font-size: calc(var(--base-px-size) * 3.555);  
}

h4 {
    font-size: calc(var(--base-px-size) * 2.666);
}

h5 {
    font-size: calc(var(--base-px-size) * 2.000);
    text-transform: uppercase;
}

h6 {
    font-size: calc(var(--base-px-size) * 1.333);
    font-weight: 900;
    text-transform: uppercase;
}

p {
    word-break: keep-all;
    
    color: var(--white);
    
    font-size: var(--base-px-size);
    font-weight: 100;
    font-family: 'Roboto', sans-serif;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (max-width: 500px) {
    p {
        font-weight: 400;
    }
}

.caption {
    font-style: italic;
}

.color-text {
    color: var(--primary);
}

.secondary {
    color: var(--black);
}

.block {
    letter-spacing: 3px;
    font-weight: 900;
    text-transform: uppercase;
}

.alt {
    font-family: 'Roboto', sans-serif;
}

.bold {
    font-weight: 700;
}

.capitalized {
    text-transform: capitalize;
}

.multiline-spacing-text {
    white-space: pre-line;
}
#menu {
    height: 100vh;
    width: 100vw;
    
    background-color: #000000AA;

    position: absolute;
    z-index: 20;
    top: 0px;
    left: 0px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    backdrop-filter: blur(7px);
    
    transition: transform 0.5s;
}

#menu h3 {
    margin: 10px;
    margin-left: 0px;
    transition: transform 0.3s;
}

#menu h3:hover {
    cursor: pointer;
    transform: scale(95%);
}

#menu h3:active {
    transform: scale(90%);
}
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;0,900;1,100&display=swap);
body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --base-px-size: 22px;
  --black: #000000;
  --white: #FFFFFF;
  --light-gray: #BFBFBF;
  --gray: #A6A6A6;
  --dark-gray: #262521;
  --primary: #DFFF00;
  --secondary: #A1A642;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #262521;
  background: var(--dark-gray);
}

::-webkit-scrollbar-thumb {
  background: #A6A6A6;
  background: var(--gray);
  border-radius: 5px;
  padding: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #BFBFBF;
  background: var(--light-gray);
}

@media screen and (max-width: 500px) {
  :root {
    --base-px-size: calc(100vw / 27.777);
  }
}

body {
  background-color: #000000;
  background-color: var(--black);
}

.app {
 min-height: 100vh;
 display: flex;
 flex-direction: column;
}
.text-box {
    padding: 15px;
    background-color: black;
    margin: 0px 0px 15px 0px;
}

.text-box * {
    margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--white);

    font-weight: 400;
    font-family: 'Playfair Display', serif;
    
    line-height: 95%;
    
    word-break: keep-all;
    white-space: pre-line;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

h1 {
    color: var(--primary);

    font-size: calc(var(--base-px-size) * 5.333);
    font-weight: 900;
}
  
  
h2 {
    font-size: calc(var(--base-px-size) * 3.555);
    font-weight: 700;
}
  
h3 {
    font-size: calc(var(--base-px-size) * 3.555);  
}

h4 {
    font-size: calc(var(--base-px-size) * 2.666);
}

h5 {
    font-size: calc(var(--base-px-size) * 2.000);
    text-transform: uppercase;
}

h6 {
    font-size: calc(var(--base-px-size) * 1.333);
    font-weight: 900;
    text-transform: uppercase;
}

p {
    word-break: keep-all;
    
    color: var(--white);
    
    font-size: var(--base-px-size);
    font-weight: 100;
    font-family: 'Roboto', sans-serif;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (max-width: 500px) {
    p {
        font-weight: 400;
    }
}

.caption {
    font-style: italic;
}

.color-text {
    color: var(--primary);
}

.secondary {
    color: var(--black);
}

.block {
    letter-spacing: 3px;
    font-weight: 900;
    text-transform: uppercase;
}

.alt {
    font-family: 'Roboto', sans-serif;
}

.bold {
    font-weight: 700;
}

.capitalized {
    text-transform: capitalize;
}

.multiline-spacing-text {
    white-space: pre-line;
}
footer {
    padding: 50px;
}
#menu {
    height: 100vh;
    width: 100vw;
    
    background-color: #000000AA;

    position: absolute;
    z-index: 20;
    top: 0px;
    left: 0px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    -webkit-backdrop-filter: blur(7px);
    
            backdrop-filter: blur(7px);
    
    transition: -webkit-transform 0.5s;
    
    transition: transform 0.5s;
    
    transition: transform 0.5s, -webkit-transform 0.5s;
}

#menu h3 {
    margin: 10px;
    margin-left: 0px;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

#menu h3:hover {
    cursor: pointer;
    -webkit-transform: scale(95%);
            transform: scale(95%);
}

#menu h3:active {
    -webkit-transform: scale(90%);
            transform: scale(90%);
}
#header {
    height: 110px;
    width: 100%;

    padding: 25px;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-items: center;
}

#logo-container {
    width: 50px;
    height: 50px;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

#header-logo {
    width: 100%;
    height: 100%;
    
    transition: all 0.15s;
}

#logo-container:active #header-logo {
    width: 90%;
    height: 90%;
}

#header-logo path {
    fill: var(--primary);
}

#header-title {
    position: static;
    left: 50vw;
    text-align: left;
}

#header-hamburger {
    width: 20px;
    height: 20px;
    margin-right: 25px;
    -webkit-transform: scale(100%);
            transform: scale(100%);
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
}

#header-hamburger:hover {
    -webkit-transform: scale(110%);
            transform: scale(110%);
    margin-right: 25px;
}

#desktop-nav {
    display: none;
    margin-right: 20px;
}

.link-container {
    margin:  0px 10px;
}

.link-container p {
    color: var(--white);
    transition: color 0.25s;
}

.link-container.selected p {
    color: var(--primary);
}

.link-container:hover p {
    color: var(--secondary);
}

#desktop-nav a {
    text-decoration: none;
}

#spacer {
    flex: 1 1;
}

@media screen and (min-width: 1024px) {
   #header-hamburger {
       display: none;
   } 
   #header-title {
       display: none;
   }
   #desktop-nav {
       display: flex;
       flex-direction: row;
   }
}
button {
    cursor: pointer;
    border-radius: 5px;
}

button, .outlined {
    background-color: var(--black);

    border: 3px solid var(--primary);
}

button, .filled {
    background-color: var(--primary);
}
  
button p {
    margin: 12px;
    
    color: var(--primary);
}

button, .filled p {
    color: var(--black);
}


.flex {
    display: flex;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

#home-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cta-container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1000px;
    padding: 15px;
}

#connect-button {
    margin-top: 30px;
}

#map-header {
    text-align: right;
}

#map {
    background-image: url("/static/media/Asset 1.afd78ef2.svg");
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 200px 100px -50px #000000, inset 0px -200px 100px -50px #000000;
}
.outer-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    flex: 1 1;
    
    width: 100%;
}

.page-container {
    margin: 15px;
    margin-bottom: 0px;
    text-align: left;
    word-wrap: normal;
    word-break: break-all;
    max-width: 1000px;
}
.service-content {
    padding-top: 10px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s;
}

.service-content.open {
    max-height: 600px;
}

.service-container h5 {
    transition: all 0.5s;
}

.service-container:hover h5 {
    color: var(--primary);
}

.service-container,.open h5{
    color: var(--primary);
}
.input {
    font-size: var(--base-px-size);
    padding: 11px;
    box-sizing: border-box;
    border: 1px solid white;
    background-color: black;
    color: white;
    border-radius: 5px;
    margin: 10px 0px;
    width: 100%;
    display: block;
}
#about-container {
    margin-bottom: 50px;
}
#work-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
}

#work-nav p {
    margin: 0px 15px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
}

#work-nav p.selected {
    color: #DFFF11;
}

#coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;

    margin-top: -75px;
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;0,900;1,100&display=swap');

:root {
  --base-px-size: 22px;
  --black: #000000;
  --white: #FFFFFF;
  --light-gray: #BFBFBF;
  --gray: #A6A6A6;
  --dark-gray: #262521;
  --primary: #DFFF00;
  --secondary: #A1A642;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--dark-gray);
}

::-webkit-scrollbar-thumb {
  background: var(--gray);
  border-radius: 5px;
  padding: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--light-gray);
}

@media screen and (max-width: 500px) {
  :root {
    --base-px-size: calc(100vw / 27.777);
  }
}

body {
  background-color: var(--black);
}

.app {
 min-height: 100vh;
 display: flex;
 flex-direction: column;
}
.service-content {
    padding-top: 10px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s;
}

.service-content.open {
    max-height: 600px;
}

.service-container h5 {
    transition: all 0.5s;
}

.service-container:hover h5 {
    color: var(--primary);
}

.service-container,.open h5{
    color: var(--primary);
}